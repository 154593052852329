/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    ol: "ol",
    li: "li",
    p: "p"
  }, _provideComponents(), props.components);
  return React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "The Terms of Service and its Annexes, Attachments, Transaction Documents, Data Processing Agreement and Joint Controller Agreement supersede any course of dealing, discussions or representations between you and us."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "In the event of conflict:"), "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "an Attachment prevails over this Terms of Service and its Annexes,"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "a Transaction Document prevails over the Terms of Service, its Annexes and any Attachment,"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "a Data Processing Agreement prevails over the Terms of Service, its Annexes, Attachments and Transaction Documents,"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "a Joint Controller Agreement prevails over the Terms of Service, its Annexes, Attachments and Transaction Documents."), "\n"), "\n"), "\n"), "\n");
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
